/** @jsx jsx */
import { useEffect, useState } from 'react'
import { jsx } from 'theme-ui'
import Versus from '../versus'
import { getNextMatch, getCurrentMatch } from '../../api_aws/opta'

/**
 * This shows the current match fixture if there is one, otherwise it shows the next match
 */
const CurrentMatch = props => {
  const [match, setMatch] = useState()

  useEffect(() => {
    ;(async () => {
      const current = await getCurrentMatch()
      if (current.success) {
        setMatch(current.data)
        return
      }
      const next = await getNextMatch()
      if (next.success) {
        setMatch(next.data)
      }
    })()
  }, [setMatch])

  if (!match) return null

  return <Versus {...props} fixtureData={match} />
}

export default CurrentMatch

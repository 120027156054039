import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Feed from './index'

const All = props => {
  const data = useStaticQuery(graphql`
    query AllNewsQuery {
      allContentfulNews(sort: { fields: date, order: DESC }) {
        edges {
          node {
            date
            title
            slug
            video
            videoEmbed
            excerpt
            thumbnail {
              file {
                url
              }
              gatsbyImageData(layout: FIXED, width: 550, height: 550)
            }
            article {
              ... on ContentfulArticle {
                title
                main {
                  raw
                  references {
                    ...ArticleFragment
                  }
                }
              }
            }
            category {
              name
              id
              slug
            }
          }
        }
      }
    }
  `)

  return <Feed {...props} data={data} />
}

export default All

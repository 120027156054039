/** @jsx jsx */
import { jsx, Text, Grid } from 'theme-ui';
import Layout from '../../components/layout';
import Hero from '../../components/hero'
import { responsive } from '../../utils/helpers'
import Wrapper from '../../components/layout/wrapper'
import Inner from '../../components/layout/inner'
import { ImageCard } from '../../components/card'
import CurrentMatch from '../../components/currentMatch'
import { News } from '../../components/feed'
import MetaData from '../../components/metadata'

import VideoBG from '../../images/fgr-video.png'
import AudioBG from '../../images/fgr-live-audio-thumb.jpg'
import VideoIcon from '../../images/fgr-live.png'
import AudioIcon from '../../images/fgr-live-audio.png'


const FGRLive = (props) => {

  const cardHeading = {
    fontFamily: 'headingalt',
    lineHeight: 'heading',
    fontSize: 11
  }

  return (
    <Layout {...props}>

      <MetaData title="FGR Live" />

      <Hero
        data={[
          {
            type: 'Custom',
            children: (
              <CurrentMatch 
                sx={{position: 'relative'}} 
                title="Follow the match with FGR Live"
                variant="large" 
              />
            )
          }
        ]}
      />
      <Wrapper>
        <Inner>
          <Grid columns={responsive(1, 2)} gap={4} mt={8} mb={4}>
            <ImageCard 
              featured={true}  
              thumbnail={{
                url: VideoBG
              }}
              title={<Text sx={cardHeading}><Text color='primary' mr={2}>FGR Live</Text><Text>Video</Text></Text>}
              url="/match-centre/fgr-live/video"
              icon={<img width="60" height="60" src={VideoIcon} alt="Watch" />}
            />
            <ImageCard 
              featured={true}  
              thumbnail={{
                url: AudioBG
              }}
              title={<Text sx={cardHeading}><Text color='primary' mr={2}>FGR Live</Text><Text>Audio</Text></Text>}
              url="/match-centre/fgr-live/audio"
              icon={<img width="60" height="60" src={AudioIcon} alt="Listen" />}
            />
          </Grid>
        </Inner>
      </Wrapper>

      <Wrapper sx={{my: 12}}>
        <Inner>
          <News title={{label: 'Latest FGR News'}} button={{label: 'See more', url: '/news'}} itemsToShow={4} />
        </Inner>
      </Wrapper>
      
    </Layout>
  )
}

export default FGRLive;
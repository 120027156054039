/** @jsx jsx */
import { jsx, Button, Box, Grid, Flex } from 'theme-ui'
import React, { useState, useEffect, useCallback } from 'react'
import update from 'immutability-helper'
import { responsive, chunk } from '../../utils/helpers'
import Masonry from 'react-masonry-css'
import { VideoCard } from '../../components/video'
import { CaptionCard } from '../../components/card'
import { useMediaQuery } from 'react-responsive'
import Video from './video'
import News from './news'
import All from './all'
import SectionHeader from '../sectionHeader'

const Promo = ({ promos, count }) => {
  if (!promos) return null

  return promos
    .filter(x => {
      return x.place === count
    })
    .map(x => {
      return x.component
    })
}

const Item = ({
  node,
  featured,
  className,
  itemHeight,
  count,
  promos,
  scrollY,
}) => {
  if (!node) return null
  const data = {
    ...node,
    thumbnail: { url: node.thumbnail?.file?.url },
    gatsbyThumbnail: node.thumbnail?.gatsbyImageData,
    category: node.category ? { ...node.category, flourish: true } : false,
  }

  const card = node.video ? (
    <VideoCard
      featured={featured}
      {...data}
      className={className}
      height={itemHeight}
      state={scrollY >= 0 ? { scrollY } : false}
    />
  ) : (
    <CaptionCard
      featured={featured}
      {...data}
      url={`/news/${node.slug}`}
      className={className}
      height={itemHeight}
    />
  )

  return (
    <React.Fragment>
      {card}
      <Promo count={count} promos={promos} />
    </React.Fragment>
  )
}

const Layout = ({ pagination, className, children }) => {
  if (pagination) {
    return (
      <Box className={className}>
        <Masonry
          breakpointCols={{
            default: 3,
            900: 2,
            700: 1,
          }}
          className="masonry"
          columnClassName="masonry-column"
        >
          {children}
        </Masonry>
      </Box>
    )
  }
  return (
    <Grid gap={4} columns={responsive(1, 4)} mb={4} mt={4}>
      {children}
    </Grid>
  )
}

const Feed = props => {
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [stories, setStories] = useState([])
  const { edges } = props.data.allContentfulNews
  const [hasMounted, setHasMounted] = useState(false)
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1240px)' })

  useEffect(() => {
    setHasMounted(true)
  }, [])

  const filter = useCallback(
    edges => {
      const categories = props.category
        ? edges.filter(x => x.node?.category?.slug === props.category)
        : edges

      const filtered =
        props.filterType && ['video', 'article'].includes(props.filterType)
          ? categories.filter(x => {
              return props.filterType === 'video'
                ? x.node?.video
                : !x.node?.video
            })
          : categories
      return filtered
    },
    [props.category, props.filterType]
  )

  const offset = useCallback(
    edges => {
      if (!props.offset) return edges
      return update(edges, {
        $splice: [[0, props.offset]],
      })
    },
    [props.offset]
  )

  useEffect(() => {
    const filtered = filter(edges)
    const offsetted = offset(filtered)
    const chunks = chunk(offsetted, props.itemsToShow)
    setTotalPages(chunks.length)
    if (!chunks.length) return
    setStories(s =>
      update(s, {
        $push: chunks[currentPage],
      })
    )
  }, [currentPage, edges, props.itemsToShow, props.offset, filter, offset])

  const masonrySX = {
    marginBottom: 9,

    '.masonry': {
      display: 'flex',
      marginLeft: -5,
      width: 'auto',
    },

    '.masonry-column': {
      paddingLeft: 5,
      backgroundClip: 'padding-box',

      '> a, > div': {
        mb: 4,
      },
    },
  }

  const paginationSX = {
    justifyContent: 'center',
    paddingTop: 6,
    paddingBottom: 6,
  }

  const cardSX = {
    minHeight: '340px',
    mb: 4,
  }

  return (
    <Box
      sx={{ overflowAnchor: 'none' }}
      key={hasMounted ? 'mounted' : 'unmounted'}
    >
      {props.title && (
        <SectionHeader title={props.title.label} button={props.button} />
      )}
      {props.featured && (
        <Grid columns={responsive(1, 2, 5)} mt={4}>
          {[...Array(2)].map((_, i) => {
            return (
              <Item
                key={i}
                sx={cardSX}
                featured={isTabletOrMobile ? false : true}
                itemHeight={props.itemHeight}
                {...offset(filter(edges))[i]}
                count={i}
                scrollY={props.scrollY}
              />
            )
          })}
        </Grid>
      )}
      <Layout pagination={props.pagination} sx={masonrySX}>
        {stories
          .filter((_, i) => {
            return !props.featured || i > 1
          })
          .map((x, i) => {
            return <Item {...x} count={i} key={i} scrollY={props.scrollY} />
          })}
      </Layout>
      {currentPage < totalPages - 1 && props.pagination && (
        <Flex sx={paginationSX}>
          <Button
            variant="tertiary"
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            Load More
          </Button>
        </Flex>
      )}
    </Box>
  )
}

export { Video, News, All }
export default Feed
